<template>
    <div class="page-content">


        <div class="table-content">
            <div class="table-top">
                <div class="table-top-text"></div>
            </div>
            <el-table :data="tableData" stripe
                      v-loading="loading"
                      cell-class-name="text-center"
                      header-cell-class-name="header-row-class-name">
                <el-table-column prop="value" label="角色名称" min-width="120px"></el-table-column>
                <el-table-column prop="updated_at" min-width="160px" label="更新时间"></el-table-column>
                <el-table-column width="200" fixed="right">
                    <template slot-scope="{row}">
                        <div class="btn-content">

                            <el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
                                       @click="handShowEdit(row)"></el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination-content">
                <el-pagination
                        background
                        :current-page.sync="searchForm.page"
                        :page-size="searchForm.limit"
                        @current-change="searchForm.page=$event"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </div>


        <HgDialog title="编辑内容" :width="800" :maskClose="false"     v-model="showEdit">
            <RichText  v-model="contents"></RichText>
            <div class="fill-btn" style="margin: 10px auto"  v-load="editing" @click="editWebSet">保存</div>
        </HgDialog>

    </div>
</template>

<script>
    import {delToast,formVerify} from './../../utlis/decorators'
   import  RichText from  './../../components/RichText'

    export default {
        components: {
            RichText
        },
        name: "roleManage",
        data() {
            return {
                loading: false,
                editing: false,
                showAdd: false,
                showEdit: false,
                giveAuth: false,
                menuIds:[],
                searchForm: {
                    page: 1,
                    limit: 10,
                    name: ''
                },
                total: 0,
                authList: [],
                props:{
                    label: 'name',
                    children: 'children'
                },
                tableData: [],
                form: {
                    name: ''
                },
                currentId: '',
                menu_ids:[],
                contents:''
            }
        },
        created() {
            this.getSetList();
            // this.getPermissionList();
        },
        watch: {
            'searchForm.page': 'getRoleList'
        },
        computed: {
            getRules() {
                return {
                    name: [{required: true, message: `角色名称不能为空`, trigger: ['blur', 'change']}],
                }
            }
        },
        methods: {

            /**
             * 获取内容列表
             * @returns {Promise<void>}
             */
            async getSetList() {
                this.loading = true;
                let [e, data] = await this.$api.getWebSet(this.searchForm);
                this.loading = false;
                if (e) return;
                if (data.code === 200) {
                    this.tableData = data.data;
                    // this.total = data.data.total;
                }
            },
            handShowEdit({id, content}) {
                this.currentId = id;
                this.contents = content;
                this.showEdit = true;
            },
            /**
             * 编辑内容
             * @returns {Promise<void>}
             */
            // @formVerify('editForm')
            async editWebSet() {
                console.log(1);
                this.editing = true;
                let [e, data] = await this.$api.saveWebSet({
                    id: this.currentId,
                    content: this.contents
                });
                this.editing = false;
                if (e) return;
                if (data.code === 200) {
                    this.getSetList();
                    this.showEdit = false;
                    this.$message.success('保存成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            },
            /**
             * 赋予权限
             * @returns {Promise<void>}
             */
            async setRolePermission(){
                this.editing = true;
                let [e, data] = await this.$api.saveRoleMenu({
                    id: this.currentId,
                    menu_ids:this.menu_ids
                });
                this.editing = false;
                if(e) return;
                if(data.code === 200){
                    this.getRoleList();
                    this.giveAuth = false;
                    this.$message.success('保存成功')
                }else {
                    this.$message.warning(`${data.msg}`)
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .page-content {
        width: calc(~'100% - 60px');
        margin: 26px 30px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 30px;

        .park-form {
            border-bottom: 1px solid #efefef;
        }

        .table-content {
            padding-top: 20px;

            .table-top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .table-top-text {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(35, 39, 52, 1);
                }

                .table-top-btn {
                }
            }
        }
    }

    .btn-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .iconfont {
            font-size: 20px;
            color: rgb(190, 199, 213);
            margin: 0 10px;
            cursor: pointer;
        }

        .icon-shanchu {
            font-size: 24px;
        }
    }

    .box-shadow {
        width: calc(~'100% + 40px');
        height: 50px;
        position: absolute;
        top: 0;
        left: -20px;
        z-index: 10;
        box-shadow: 0 0 4px #b4b4b4;
    }
</style>
